@import "common";

// ------
// components
@import "../../../../../../parent-theme/resources/assets/sass/components/products";
@import '../../../../../../parent-theme/resources/assets/sass/components/carousel';


// ------
// page
main {
    background-color: #f4f6f9;
    background-image: url("/img/eonsmoke_backgr_page.png");
    background-repeat: no-repeat;
    background-position: left -104px bottom -40px;
    padding-bottom: 31px;
    overflow: hidden; // fix carousel

    @media (min-width: 767px) {
        padding-bottom: 90px;
    }
}

figure {
    margin: 0;
}

// -----
// carousel: handle homepage carousels
$carousel-item-padding-x: 8px;

.owl-carousel {
    margin-left: -$carousel-item-padding-x;
    margin-right: -$carousel-item-padding-x;
}

.owl-item {
    padding-left: $carousel-item-padding-x;
    padding-right: $carousel-item-padding-x;
}


// see all button
.view-all-btn {
    color: #51545a;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    line-height: 1.2;

    svg {
        margin-left: 9px;
    }
}

// Home area banner
@import "../components/hero-banner";


// ------
// featured products
@import "../components/products-featured-banner";


// ------
// sections: wrap featured categories, blog posts and reviews
.home-section {
    margin-top: 45px;

    > h2 {
        padding-bottom: 22px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 5px;
        text-transform: uppercase;
        text-align: center;
        background: url("/img/pineapple_leaf.png") bottom no-repeat;
        background-repeat: no-repeat;
        margin: 0 0 40px;
    }

    @media (min-width: 1024px) {
        margin-top: 90px;
    }
}


// ------
// Featured categories area 
@import "../components/categories-featured";

// ------
// Blog posts area 
@import "../components/blog-posts-carousel";

// ------
// reviews/feedbacks
@import "../components/feedback-carousel";


// ------
// about
@import "../components/welcome";


// -----
// 
.footer {
    margin-top: 0; // don't remove it.. the footer has a margin-top of 90px to all other pages
}