// Making changes to this file
// 
// 1. Enable the overwrite variable related to this component in _variables.scss under "component overwrites"
// 2. Changes here will affect the component in all pages its been added
@mixin products {
    .price--special {
        font-size: rem(20);
        font-weight: 700;
    }

    .price--as-low-as {
        > span:last-child {
            color: $product-price-special-color;
            font-weight: $product-price-special-font-weight;
            font-size: rem(20);
        }
    }

    // 
    .product {
        .price--as-low-as {
            > span:first-child {
                font-size: 14px;
            }
        }

        &__price {
            margin-bottom: 0.850rem;
        }

        .message--us-fda-approval {
            margin-top: auto;
            order: 30;
        }
    }

    .product__badge span {
        background-color: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: 10px;

        @media (min-width: 768px) {
            width: 46px;
            height: 46px;
            font-size: 12px;
        }
    }
}