// About area
.about-area-wrap {
    background-color: #f4f6f9;
    position: relative;
    border: 1px solid #cdcfd1;
    margin-top: 42px;

    @media (min-width: 1310px) {
        display: flex;
        flex-direction: row;
    }

    .welcome-wrap {
        @media (min-width: 1310px) {
            transform: rotate(90deg);
            align-self: flex-start;
            position: relative;
            top: 90px;
            border-top: 1px solid #d4d7d9;
            padding-right: 21px;
        }

        .welcome-title {
            text-align: center;
            margin-bottom: 0;

            @media (min-width: 1310px) {
                background: unset;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 66px;
                    height: 8px;
                    top: 10px;
                    background: url(/img/pineapple_leaf.png) 0 0 no-repeat;
                    transform: rotate(90deg);
                    right: -42px;
                }
            }
        }
    }

    .about-area-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;

        @media (min-width: 767px) {
            display: flex;
            font-size: 14px;

            > .col {
                &:first-child {
                    padding-right: 21px;
                    flex: 0 1 50%;
                }

                & + .col {
                    padding-left: 21px;
                    flex: 0 1 50%;
                }
            }
        }
    }

    .about-area-content {
        padding: 21px;

        @media (min-width: 1310px) {
            padding-top: 0;
            padding-right: 35px;
            padding-top: 38px;
        }

        .read-more-wrap {
            text-align: right;
            margin: 30px 0 0;
        }
    }
}
