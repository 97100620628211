.homepage-banner-wrap {
    background-color: #535453;

    @media (min-width: 768px) {
        background-image: url("/img/banner_backgr.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .wrapper {
        padding: 21px 0;
    }
}

.column-content {
    margin: -8px;

    > div {
        padding: 8px;
    }

    @media (min-width: 1270px) {
        display: flex;
    }
}

// ---
// left column
.sale-column {
    color: #323332;

    > div {
        background-repeat: no-repeat;
        background-position: bottom left;
        min-height: 330px;
        height: 100%;
    }

    span {
        font-size: 27px;
        font-weight: 300;

        &:first-child {
            font-weight: 600;
        }
    }

    .discount-text {
        margin-top: 18px;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 18px;
    }

    a {
        span {
            font-size: 14px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .promo_text {
        display: block;
        margin-bottom: 21px;
    }

    .promo_text_copy {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        border-radius: 3px;
        padding: 15px;
        display: inline-block;
        height: 45px;
        box-sizing: border-box;
        margin-bottom: 0;
        line-height: 1.4;
    }

    @media (min-width: 1270px) {
        flex: 0 1 60%;
        min-height: 450px;
    }

    @media (min-width: 1520px) {
        max-width: 828px;
    }
}

.fda-msg-small {
    @media (max-width: 520px) {
        line-height: 48px !important;
    }

    @media (max-width: 1521px) and (min-width: 520px) {
        line-height: 48px !important;
    }
}

// ---
// right column
.products-column {
    a {

        @media (max-width: 1023px) { 
            background: #f4f6f9 !important;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .product-title {
        display: block;
        color: #323332;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 6px;
        hyphens: auto;
        font-size: 20px;
    }

    // eonsmoke
    .eonsmoke {
        a {
            display: flex;
            position: relative;
            background-image: url("/img/eonsmoke_backgr.png");
            background-repeat: no-repeat;
            background-position: left 21px bottom 17px;
            padding: 17px 21px;
            height: 255px;

            @media (min-width: 767px) {
                background-position: left 326px bottom 17px;
            }
        }

        img {
            display: none;

            @media (min-width: 768px) {
                display: block;
                margin-right: 50px;
            }
        }

        .title-wrap {
            align-items: flex-end;
            color: #6ab131;
            font-size: 14px;

            @media (min-width: 768px) {
                margin-top: 18px;
            }
        }

        .product-title {
            line-height: 1;
        }

        .view-all-btn {
            position: absolute;
            bottom: 15px;
            right: 21px;
            top: unset;
        }
    }

    // logic
    .logic {
        figure {
            margin-top: 16px;
        }

        a {
            display: flex;
            height: 100%;
            padding: 18px 21px 18px 16px;

            &:hover .title-wrap span {
                text-decoration: underline;
            }
        }

        .img-wrap {
            // margin-right: 21px;
            // width: 63px;
            text-align: center;

            // img {
            //     max-height: 138px;
            // }
        }

        > div:first-child {
            .title-wrap {
                margin-top: 29px;
                margin-left: 17px;
                // background: url("/img/logic_pro_pen.png") left center no-repeat;
                background-size: auto;

                @media (max-width: 1270px) {
                    background: unset;
                }
            }

            @media (min-width:1024px) and (max-width:1270px) {
                width:30%;
                flex:unset;
                margin-right: 16px;
            }

            .view-all-btn {

                @media (max-width:1024px) {
                    align-self: unset;
                    margin-right: 0;
                }

                @media (min-width:1024px) and (max-width:1270px) {
                    align-self: unset;
                } 
            }
        }

        > div:nth-child(2) {
            .view-all-btn {
                align-self: unset;
                margin-right: 0;
           } 

           @media (min-width:1024px) and (max-width:1270px) {
                width:70%;
                flex:unset;
            }

            figure {
                @media (min-width:1024px) and (max-width:1270px) {  
                    margin-top: 0;
                }

                &:first-child {
                    a {
                        background: center / cover no-repeat  url("/img/logic_pro_2_backgr.png");
                    }
                }

                &:nth-child(2) {

                    a {
                        background: center / cover no-repeat url("/img/juul_device_2_backgr.png");
                    }

                    .title-wrap {
                        margin-left: 10px;
                        

                        @media (max-width: 1024px) {
                            margin-left: 25px;
                        }
                    }
                }
            }
        }

        .title-wrap {
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            hyphens: auto;
            flex: 1;
            margin-left: 26px;
            margin-top: 0;

            @media (max-width: 767px) {
                justify-content: center;
            }
        }

        // .product-title {
        //     margin-bottom: auto;
        // }

        .view-all-btn {
            white-space: nowrap;
        }

        // responsive
        @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

           > div:nth-child(2) {
               figure {
                flex: 0 1 50%;
                max-width: 50%;

                &:first-child {
                   margin-right: 16px;
                }
               }
           }


            .img-wrap {          
                display: flex;
                align-self: flex-end;
            }

            > div {
                flex: 0 0 50%;
            }

            > div:first-child {
                figure {
                    display: block;
                    max-width: unset;
                    padding-right: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            > div:nth-child(2) {
                display: flex;
            }
        }

        @media (min-width:1024px) and (max-width:1270px) {
            flex-direction: row;
        }


        @media (min-width: 1270px) {
            flex: 0 1 60%;
            min-height: 450px;
        }

        > div:first-child {
            figure {
                margin-top:0;
            }
        }

        .main-product-logic {
            .title-wrap {
                @media (max-width:1270px) {
                    margin-top: 0 !important;
                }
            }
        }


        .main-product-logic figure a {

            background: center / cover no-repeat  url("/img/main_product_backgr.png");

            @media (max-width:1270px) {
                background-position: right;
            }

            .img-wrap {
                @media (min-width: 1270px) {
                    margin-left: 20px;
                }
            }
        }
    }

    @media (min-width: 1270px) {
        flex: 0 1 40%;
        max-width: 552px;
    }
    
}

// ---
// Banner alert
.banner-alert {
    background-color: #fbf03a;
    font-weight: 600;
    margin-top: 21px;
    padding: 15px 30px;
}
