.products {
    @include list-unstyled;
    @include make-row;
    margin-top: -(rem($product-listing-spacing-y)) !important;
    position: relative;
    z-index: 2;
    
    > .product {
        @include make-col-ready;
        @include make-col($product-listing-per-row-computed);
        padding-top: rem($product-listing-spacing-y);
        margin-top: 0;
    
        // ---
        // responsive
        @include media-breakpoint-up(xl) {
            @if ($product-listing-hover-effect) {
                &:hover {
                    z-index: 2;
    
                    .product__inner::before {
                        background: $white;
                        box-shadow: $component-box-shadow;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.product__inner {
    > * {
        position: relative;
    }

    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;

    // ---
    // responsive
    @include media-breakpoint-up(xl) {
        padding: $product-listing-inner-padding;
    }
}

.product {
    a:not(.button) {
        background: none;
        text-decoration: none;
    }
}

// ---
// image
.product__image {
    position: relative;
    order: $product-listing-image-order;

    // link
    > a {
        display: block;
        position: relative;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    img {
        display: block;
        height: auto;
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

// ---
// badges
.product__badge {
    display: flex;
    align-items: center;
    position: absolute;
    left: $product-badge-position-x;
    top: $product-badge-position-y;
    z-index: 1;

    span {
        border: $product-badge-border-width $product-badge-border-style
            $product-badge-border-color;
        border-radius: $product-badge-border-radius;
        color: $product-badge-color;
        font-family: $product-badge-font-family;
        // font-size: rem($product-badge-font-size);
        font-weight: $product-badge-font-weight;
        line-height: $product-badge-line-height;
        padding: $product-badge-padding-y $product-badge-padding-x;
        text-transform: uppercase;
        text-align: center;
    }

    // ---
    // responsive
    @include media-breakpoint-up(xl) {
        @if ($product-listing-hover-effect) {
            left: -$product-listing-inner-padding;
            top: -$product-listing-inner-padding;
        }
    }
}

.badge__sale {
    background: $product-badge-sale-bg;
}

.badge__new {
    background: $product-badge-new-bg;
}

// ---
// title
.product__title {
    color: $product-title-color;
    display: block;
    font-weight: $product-title-font-weight;
    font-size: rem($product-title-font-size);
    line-height: calc(#{rem($product-title-font-size) + #{$product-line-height}});
    margin-top: $product-title-spacing-y;

    > a {
        display: block;
        color: inherit;
    }

    &:hover {
        color: $product-title-color-hover;
    }
}

.product__model {
    color: inherit;
    // font-size: rem($product-price-font-size);
    // line-height: calc(
    //     #{rem($product-price-font-size) + #{$product-line-height}}
    // );
    margin-top: 5px;
    order: $product-listing-model-order;
}

// ---
// price
@import "../components/price";

// ---
// reviews
@import "../components/stars";

.product__review {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: $product-listing-review-spacing-y-top;
    margin-bottom: $product-listing-review-spacing-y-bottom;
    order: $product-listing-review-order;

    .review__stars {
        font-size: inherit;

        &__off {
            letter-spacing: 0;
        }
    }

    a {
        font-size: $font-size-sm;

        &::before {
            content: "(";
        }

        &::after {
            content: ")";
        }

        span {
            text-decoration: underline;
        }
    }
}

// ---
// add to cart
.product__add-cart {
    z-index: 3;
    transition: all 0.5s;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    order: $product-listing-add-to-cart;
    margin-top: 30px;
    
    // only hiden button if there is hover effect, 
    @if ($product-listing-addcart-show-only-hover) {
        position: absolute;
        right: 0;
        left: 0;
        display: none;
        bottom: $product-listing-addcart-bottom;
        padding: $product-listing-inner-padding;
    }

    input {
        @if ($product-listing-addcart-qty-enable) {
            width: 40px;
        } @else {
            display: none;
        }
    }

    button {
        flex: 1;
        width: 100%;
        @if ($product-listing-addcart-qty-enable) {
            margin-left: $elements-spacing;
        }
    }
} 

// ---
// status
@include media-breakpoint-up(xl) {
    @if ($product-listing-addcart-show-only-hover) {
        .product.has-addbutton:hover {
            .product__inner::before {
                bottom: $product-listing-addcart-bottom;
            }

            .product__add-cart {
                display: block;
            }
        }
    }
}

// ---
// save for later
@import "save_for_later";

// ---
// theme overwrites
@if (variable-exists(products-overwrite)) {
    @if ($products-overwrite) {
        @include products;
    }
}