.popup {
    .help-block {
        margin-bottom: 0;
        margin-top: 10px;
    }

    &__feedback {
        display: none;
        margin-top: 10px;

        .message {
            margin-bottom: 10px;
        }
    }

    &--create {
        transition: .5s opacity;

        .is-disabled {
            opacity: .75;
            pointer-events: none;
        }
    }
}