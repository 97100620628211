// ---
// component
.save-for-later {
    display: inline-flex;
    margin-bottom: 0;
    position: absolute;
    right: 4px;
    bottom: 4px;

    // span:empty {
    //     display: none;
    // }

    svg,
    img {
        display: block;
        position: static;
    }

    // ---
    // not absolute
    &--static {
      position: relative;
      right: 0;
      bottom: 0;
    }

    // ---
    // save list trigger
    .save-for-later__trigger {
        color: $save-for-later-trigger-color;
        display: flex;
        align-items: center;
        line-height: 1;

        span:first-child {
            display: flex;
            align-items: center;

            + * {
                margin-left: $elements-spacing;
            }
        }

        svg {
            height: $save-for-later-trigger-icon-height;
            width: $save-for-later-trigger-icon-width;
        }
    }

    // ---
    // save list
    // .save-for-later__options {}

    // ---
    // save list item
    // .save-for-later__item {}

    // ---
    // save list link
    .save-for-later__link {
        display: flex;
        align-items: center;
        font-size: $save-for-later-font-size;
    }

    .save-for-later__item--new {
        &:not(:nth-child(1)) {
            border-top: $component-border-width $component-border-style
                $component-border-color;
            margin-top: $elements-spacing;
            padding-top: $elements-spacing;
        }
    }

    // ---
    // save list link icon
    .save-for-later__icon {
        display: flex;
        align-items: center;
        margin-right: $elements-spacing;
        height: $save-for-later-image-container-height;
        width: $save-for-later-image-container-width;

        svg,
        img {
            height: $save-for-later-image-container-height;
            width: $save-for-later-image-container-width;
        }

        + span {
            flex: 1;
        }
    }

    // ---
    // on: hover
    &:hover {
        .save-for-later__trigger {
            color: $save-for-later-trigger-color-hover;
        }
    }
}

// ---
//
.save-option {
    a {
        &.is-saved-for-later {
            background: #dff0d8;
        }

        &.is-saving-for-later {
            opacity: 0.7;
            cursor: wait;
            pointer-events: none;
        }
    }
}

// ---
// inside product
.product {
    .save-for-later {
        display: none;
        margin-top: 17px;
    }

    &:hover {
        .save-for-later {
            display: block;
        }
    }
}

@import "save_for_later_popup_feedback";

// ---
// theme overwrites
@if (variable-exists(save-for-later-overwrite)) {
    @if ($save-for-later-overwrite) {
        @include save-for-later;
    }
}
