@font-face {
    font-family: "star";
    src: url("/fonts/star.ttf?czp1y3") format("truetype"),
        url("/fonts/star.woff?czp1y3") format("woff"),
        url("/fonts/star.svg?czp1y3#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "star" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.stars__on,
.stars__off,
.star-rating a:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "star" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.stars {
    color: $stars-color;
    display: flex;
    font-size: inherit;
    line-height: 1;
    letter-spacing: $stars-letter-spacing;
    overflow: hidden;
    
    &__on::before,
    &__off::before {
        font-style: normal;
        font-weight: normal;
    }
    
    &__off {
		position: relative;

        &::before {
            content: "\e902\e902\e902\e902\e902";
            color: $stars-color-off;
        }
    }

    &__on {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;

        &::before {
            content: "\e902\e902\e902\e902\e902";
            color: $stars-color;
        }
    }

    // link
    & + a {
        font-size: 1rem;
        line-height: 1.35;
    }
}

.review__stars {
    color: $stars-color-off;
    display: inline-block;
    font-size: rem(20);
    line-height: 100%;
    position: relative;

    &__off {
        letter-spacing: 0;
    }

    &__on {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.review__qty {
    margin-left: 9px;
}

// ---
// form stars
// 1. overwrite jq-rating plugin display: none; making validation works
.form-group--stars {
    display: flex;

    input[type="radio"] {
        display: block !important; // 1
        @include sr-only;
    }

    .star-rate-text {
        margin-left: $elements-spacing;
    }
}

.star-rating-control {
    display: flex;
    align-items: center;
}

.star-rating {
    background: transparent;
    cursor: pointer;
    display: block;
    overflow: hidden;
    // margin-left: -4px;

    a {
        color: $stars-color-off;
        display: block;
        background: transparent;
        border: 0;
        margin: 0;
        position: relative;
        font-size: rem(24);
        text-indent: -9999px;
        width: 24px;
        line-height: 100%;
    }

    a:before {
        content: "\e902";
        position: absolute;
        left: 9999px; /* same value as .star-rating a text-indent */
    }

    a:hover {
        color: $stars-color;
        text-decoration: none;
    }

    &-on a,
    &-hover a {
        color: $stars-color;
    }

    &-on a:before,
    &-hover a:before {
        content: "\e902";
    }

    &-readonly a {
        cursor: default !important;
    }
}

.rating-cancel {
    display: none !important;
}

// ---
// theme overwrites
@if (variable-exists(stars-overwrite)) {
    @if ($stars-overwrite) {
        @include stars;
    }
}
