// ------
// Reviews area 

.review-item {
    height: 100%;

    > div {
        background-color: #fff;
        height: 100%;
        width: 100%;
        padding: 21px;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            flex-direction: row;
            padding: 30px;
        }
    }
}

.author-wrap {
    padding-top: 12px;
    text-align: center;
    
    @media (min-width: 1px) and (max-width: 1023px) {
        border-bottom: 1px solid #f4f6f9;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    @media (min-width: 1024px) {
        border-right: 1px solid #f4f6f9;
        padding-right: 30px;
        margin-right: 30px;
    }

    #author-info {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        line-height: 1.5;
        color: #a6a8a9;
        text-align: center;

        span {
            max-width: 100%;
            margin: 0 auto;
            width: 70%;
        }
    }

    .date-published {
        font-size: 12px;
        text-align: center;
    }

    [id*="review"] {
        width: 103px;
        height: 104px;
        background-color: #f4f6f9;
        display: inline-block;
        border-radius: 50%;
        

       > span {
            width: 103px;
            height: 104px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            position: relative;
            top: -12px;
        }
    }

}

.review-content-wrap {
    
    .review-off  {
        font-size: 12px;
        line-height: 1.5;
    }

    .product-title {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: $link-color-hover;
        }
    }

    .review-title {
        font-weight: 600;
        margin: 0;
        padding-bottom: 0;

        @media (min-width: 767px) {
            font-size: 1.250rem;
        }
    }

    .review-text {
        font-size: $font-size-sm;
        padding-top: 8px;
    }

    .stars {
        margin: 15px 0 9px;
    }
}

// button
.reviews-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: $elements-spacing-lg;
}