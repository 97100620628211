.blog-posts-cont {

   .blog-post-title {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        min-height: 60px;
        margin: 0;
    }

    .blog-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        z-index: 1;
    }

    a {
        display: block;
        position: relative;
        padding: 0;
        overflow: hidden;
        height: 345px;

        img {
            display: block;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
            height: 100%;
        }

        &:hover {
            text-decoration: none;

            img {
                transform: scale(1.1);
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
}
