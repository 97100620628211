// ---
// Custom OwlCarousel
// Modified by Numinix

.owl-carousel {
	position: relative;
	touch-action: auto;
}

	.owl-stage {
		display: flex !important;
		position: relative;
		transform: translate3d(0, 0, 0);
	}

	.owl-stage-outer {
		position: relative;
		width: 100%;

		&.autoHeight {
			transition: height 500ms ease-in-out;
		}
	}

	// ---
	// item
	.owl-item:not(.active) {
		opacity: 0;
		transition: all .1s;
		pointer-events: none;
	}

.owl-dots {
	&:hover {
		cursor: pointer;
	}

	&.disabled {
		display: none;
	}
}

// buttons
.owl-prev,
.owl-next {
	background: none;
	border: none;
	padding: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;

	&.disabled {
		opacity: .5;
		pointer-events: none;
	}
}

.owl-prev {
	left: $carousel-nav-prev-left;
}

.owl-next {
	right: $carousel-nav-next-right;
}

// pagination
.owl-dots {
	display: flex;
	  align-items: center;
	  justify-content: center;
	margin-top: 30px;
	width: 100%;
}

	.owl-dot {
		border: $carousel-dot-border-width $carousel-dot-border-style $carousel-dot-border-color;
		background: $carousel-dot-bg;
		border-radius: $carousel-dot-radius;
		height: $carousel-dot-height;
		max-width: $carousel-dot-width;
		flex: 0 0 $carousel-dot-width;
		padding: 0;

		& + .owl-dot {
			margin-left: $carousel-dot-space-x;
		}

		&:hover {
			background: $carousel-dot-bg-active;
		}
	}

	.owl-dot.active {
		background: $carousel-dot-bg-active;
		height: $carousel-dot-height-active;
		max-width: $carousel-dot-width-active;
		flex: 0 0 $carousel-dot-width-active;
		pointer-events: none;
	}


// ---
// theme overwrites
@if (variable-exists(carousel-overwrite)) {
  @if($carousel-overwrite) {
    @include carousel;
  }
}