.products {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  margin-top: -2.8125rem !important;
  position: relative;
  z-index: 2;
}

.products > .product {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
  flex: 0 0 25%;
  max-width: 25%;
  padding-top: 2.8125rem;
  margin-top: 0;
}

.product__inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.product__inner > * {
  position: relative;
}

@media (min-width: 1280px) {
  .product__inner {
    padding: 0;
  }
}

.product a:not(.button) {
  background: none;
  text-decoration: none;
}

.product__image {
  position: relative;
  order: 0;
}

.product__image > a {
  display: block;
  position: relative;
}

.product__image > a:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.product__image img {
  display: block;
  height: auto;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.product__badge {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.product__badge span {
  border: 1px solid #d91a1a;
  border-radius: 50%;
  color: #d91a1a;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.15;
  padding: 0 0;
  text-transform: uppercase;
  text-align: center;
}

.badge__sale {
  background: #fff;
}

.badge__new {
  background: none no-repeat 0 0;
}

.product__title {
  color: inherit;
  display: block;
  font-weight: 400;
  font-size: inherit;
  line-height: calc(inherit + 6px);
  margin-top: 9px;
}

.product__title > a {
  display: block;
  color: inherit;
}

.product__title:hover {
  color: #528926;
}

.product__model {
  color: inherit;
  margin-top: 5px;
  order: 10;
}

.product__price {
  color: inherit;
  display: flex;
  font-size: 1rem;
  font-weight: inherit;
  line-height: calc( 1rem + 6px);
  margin-top: 12px;
  order: 20;
}

.product__price [class^="price"]:not(:last-child) {
  margin-right: 6px;
}

.product__price [class^="price"]:empty {
  display: none;
  margin-right: 0;
}

.price--normal {
  color: inherit;
  font-weight: 400;
  text-decoration: line-through;
}

.price--special {
  color: #d91a1a;
  order: -1;
}

.price--off {
  display: none;
  color: #808182;
  font-weight: 400;
  margin-left: 6px;
}

@font-face {
  font-family: "star";
  src: url("/fonts/star.ttf?czp1y3") format("truetype"), url("/fonts/star.woff?czp1y3") format("woff"), url("/fonts/star.svg?czp1y3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "star" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stars__on,
.stars__off,
.star-rating a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "star" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stars {
  color: #efce4a;
  display: flex;
  font-size: inherit;
  line-height: 1;
  letter-spacing: 1px;
  overflow: hidden;
}

.stars__on::before, .stars__off::before {
  font-style: normal;
  font-weight: normal;
}

.stars__off {
  position: relative;
}

.stars__off::before {
  content: "\e902\e902\e902\e902\e902";
  color: #f1f1f1;
}

.stars__on {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.stars__on::before {
  content: "\e902\e902\e902\e902\e902";
  color: #efce4a;
}

.stars + a {
  font-size: 1rem;
  line-height: 1.35;
}

.review__stars {
  color: #f1f1f1;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 100%;
  position: relative;
}

.review__stars__off {
  letter-spacing: 0;
}

.review__stars__on {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.review__qty {
  margin-left: 9px;
}

.form-group--stars {
  display: flex;
}

.form-group--stars input[type="radio"] {
  display: block !important;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.form-group--stars .star-rate-text {
  margin-left: 10px;
}

.star-rating-control {
  display: flex;
  align-items: center;
}

.star-rating {
  background: transparent;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.star-rating a {
  color: #f1f1f1;
  display: block;
  background: transparent;
  border: 0;
  margin: 0;
  position: relative;
  font-size: 1.5rem;
  text-indent: -9999px;
  width: 24px;
  line-height: 100%;
}

.star-rating a:before {
  content: "\e902";
  position: absolute;
  left: 9999px;
  /* same value as .star-rating a text-indent */
}

.star-rating a:hover {
  color: #efce4a;
  text-decoration: none;
}

.star-rating-on a,
.star-rating-hover a {
  color: #efce4a;
}

.star-rating-on a:before,
.star-rating-hover a:before {
  content: "\e902";
}

.star-rating-readonly a {
  cursor: default !important;
}

.rating-cancel {
  display: none !important;
}

.product__review {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0;
  order: 10;
}

.product__review .review__stars {
  font-size: inherit;
}

.product__review .review__stars__off {
  letter-spacing: 0;
}

.product__review a {
  font-size: 0.875rem;
}

.product__review a::before {
  content: "(";
}

.product__review a::after {
  content: ")";
}

.product__review a span {
  text-decoration: underline;
}

.product__add-cart {
  z-index: 3;
  transition: all 0.5s;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  order: 40;
  margin-top: 30px;
}

.product__add-cart input {
  display: none;
}

.product__add-cart button {
  flex: 1;
  width: 100%;
}

.save-for-later {
  display: inline-flex;
  margin-bottom: 0;
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.save-for-later svg,
.save-for-later img {
  display: block;
  position: static;
}

.save-for-later--static {
  position: relative;
  right: 0;
  bottom: 0;
}

.save-for-later .save-for-later__trigger {
  color: #b7b7b7;
  display: flex;
  align-items: center;
  line-height: 1;
}

.save-for-later .save-for-later__trigger span:first-child {
  display: flex;
  align-items: center;
}

.save-for-later .save-for-later__trigger span:first-child + * {
  margin-left: 10px;
}

.save-for-later .save-for-later__trigger svg {
  height: 24px;
  width: 24px;
}

.save-for-later .save-for-later__link {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.save-for-later .save-for-later__item--new:not(:nth-child(1)) {
  border-top: 1px solid #ecedee;
  margin-top: 10px;
  padding-top: 10px;
}

.save-for-later .save-for-later__icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.save-for-later .save-for-later__icon svg,
.save-for-later .save-for-later__icon img {
  height: 30px;
  width: 30px;
}

.save-for-later .save-for-later__icon + span {
  flex: 1;
}

.save-for-later:hover .save-for-later__trigger {
  color: #85b040;
}

.save-option a.is-saved-for-later {
  background: #dff0d8;
}

.save-option a.is-saving-for-later {
  opacity: 0.7;
  cursor: wait;
  pointer-events: none;
}

.product .save-for-later {
  display: none;
  margin-top: 17px;
}

.product:hover .save-for-later {
  display: block;
}

.popup .help-block {
  margin-bottom: 0;
  margin-top: 10px;
}

.popup__feedback {
  display: none;
  margin-top: 10px;
}

.popup__feedback .message {
  margin-bottom: 10px;
}

.popup--create {
  transition: .5s opacity;
}

.popup--create .is-disabled {
  opacity: .75;
  pointer-events: none;
}

.price--special {
  font-size: 1.25rem;
  font-weight: 700;
}

.price--as-low-as > span:last-child {
  color: #d91a1a;
  font-weight: 700;
  font-size: 1.25rem;
}

.product .price--as-low-as > span:first-child {
  font-size: 14px;
}

.product__price {
  margin-bottom: 0.850rem;
}

.product .message--us-fda-approval {
  margin-top: auto;
  order: 30;
}

.product__badge span {
  background-color: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 10px;
}

@media (min-width: 768px) {
  .product__badge span {
    width: 46px;
    height: 46px;
    font-size: 12px;
  }
}

.owl-carousel {
  position: relative;
  touch-action: auto;
}

.owl-stage {
  display: flex !important;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.owl-stage-outer {
  position: relative;
  width: 100%;
}

.owl-stage-outer.autoHeight {
  transition: height 500ms ease-in-out;
}

.owl-item:not(.active) {
  opacity: 0;
  transition: all .1s;
  pointer-events: none;
}

.owl-dots:hover {
  cursor: pointer;
}

.owl-dots.disabled {
  display: none;
}

.owl-prev,
.owl-next {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.owl-prev.disabled,
.owl-next.disabled {
  opacity: .5;
  pointer-events: none;
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.owl-dot {
  border: 2px solid #6ab131;
  background: #fff;
  border-radius: 50%;
  height: 12px;
  max-width: 12px;
  flex: 0 0 12px;
  padding: 0;
}

.owl-dot + .owl-dot {
  margin-left: 6px;
}

.owl-dot:hover {
  background: #6ab131;
}

.owl-dot.active {
  background: #6ab131;
  height: 15px;
  max-width: 15px;
  flex: 0 0 15px;
  pointer-events: none;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-nav,
.owl-carousel .owl-dots {
  padding: 0;
  width: auto;
}

.owl-carousel .owl-nav button,
.owl-carousel .owl-dots button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.owl-carousel .owl-nav button.disabled,
.owl-carousel .owl-dots button.disabled {
  opacity: 0.75;
  cursor: default;
  pointer-events: none;
}

.owl-carousel .owl-nav button.active,
.owl-carousel .owl-dots button.active {
  cursor: default;
}

.owl-carousel .owl-nav button span,
.owl-carousel .owl-dots button span {
  width: 100%;
  display: none;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav {
  width: auto;
}

.owl-carousel .owl-nav svg {
  display: block;
  margin: 0;
  position: absolute;
}

.owl-carousel .owl-nav button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 49px;
  height: 49px;
}

.owl-carousel .owl-nav button:hover {
  color: #719d00;
}

.owl-carousel .owl-nav .owl-prev {
  background-image: url("/img/icon-slide-arrow-left.png");
}

.owl-carousel .owl-nav .owl-next {
  background-image: url("/img/icon-slide-arrow-right.png");
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-repeat: no-repeat;
  border: 2px solid #cdcfd1;
  background-position: center;
  display: inline-block;
  cursor: pointer;
  width: 61px;
  height: 61px;
  z-index: 1500;
}

.owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-next:hover {
  border-color: #6ab131;
}

.owl-carousel .owl-prev {
  left: calc(-49px - 30px);
}

.owl-carousel .owl-prev svg {
  margin-left: -9px;
}

.owl-carousel .owl-next {
  right: calc(-49px - 30px);
}

.owl-carousel .owl-next svg {
  margin-left: -9px;
}

.owl-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.owl-carousel .owl-dots button {
  border: 2px solid #719d00;
  background: #fff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

.owl-carousel .owl-dots button:hover, .owl-carousel .owl-dots button.active {
  background: #fff;
}

.owl-carousel .owl-dots button.active {
  border-color: #6ab131;
  background-color: #6ab131;
  height: 19px;
  width: 19px;
  max-width: 19px;
  flex: 0 1 19px;
}

.owl-carousel .owl-dots button + button {
  margin-left: 9px;
}

.owl-carousel .owl-dots:empty {
  display: none;
}

main {
  background-color: #f4f6f9;
  background-image: url("/img/eonsmoke_backgr_page.png");
  background-repeat: no-repeat;
  background-position: left -104px bottom -40px;
  padding-bottom: 31px;
  overflow: hidden;
}

@media (min-width: 767px) {
  main {
    padding-bottom: 90px;
  }
}

figure {
  margin: 0;
}

.owl-carousel {
  margin-left: -8px;
  margin-right: -8px;
}

.owl-item {
  padding-left: 8px;
  padding-right: 8px;
}

.view-all-btn {
  color: #51545a;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  line-height: 1.2;
}

.view-all-btn svg {
  margin-left: 9px;
}

.homepage-banner-wrap {
  background-color: #535453;
}

@media (min-width: 768px) {
  .homepage-banner-wrap {
    background-image: url("/img/banner_backgr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.homepage-banner-wrap .wrapper {
  padding: 21px 0;
}

.column-content {
  margin: -8px;
}

.column-content > div {
  padding: 8px;
}

@media (min-width: 1270px) {
  .column-content {
    display: flex;
  }
}

.sale-column {
  color: #323332;
}

.sale-column > div {
  background-repeat: no-repeat;
  background-position: bottom left;
  min-height: 330px;
  height: 100%;
}

.sale-column span {
  font-size: 27px;
  font-weight: 300;
}

.sale-column span:first-child {
  font-weight: 600;
}

.sale-column .discount-text {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 18px;
}

.sale-column a span {
  font-size: 14px;
}

.sale-column a:hover {
  text-decoration: none;
}

.sale-column .promo_text {
  display: block;
  margin-bottom: 21px;
}

.sale-column .promo_text_copy {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 15px;
  display: inline-block;
  height: 45px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: 1.4;
}

@media (min-width: 1270px) {
  .sale-column {
    flex: 0 1 60%;
    min-height: 450px;
  }
}

@media (min-width: 1520px) {
  .sale-column {
    max-width: 828px;
  }
}

@media (max-width: 520px) {
  .fda-msg-small {
    line-height: 48px !important;
  }
}

@media (max-width: 1521px) and (min-width: 520px) {
  .fda-msg-small {
    line-height: 48px !important;
  }
}

@media (max-width: 1023px) {
  .products-column a {
    background: #f4f6f9 !important;
  }
}

.products-column a:hover {
  text-decoration: none;
}

.products-column .product-title {
  display: block;
  color: #323332;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 6px;
  hyphens: auto;
  font-size: 20px;
}

.products-column .eonsmoke a {
  display: flex;
  position: relative;
  background-image: url("/img/eonsmoke_backgr.png");
  background-repeat: no-repeat;
  background-position: left 21px bottom 17px;
  padding: 17px 21px;
  height: 255px;
}

@media (min-width: 767px) {
  .products-column .eonsmoke a {
    background-position: left 326px bottom 17px;
  }
}

.products-column .eonsmoke img {
  display: none;
}

@media (min-width: 768px) {
  .products-column .eonsmoke img {
    display: block;
    margin-right: 50px;
  }
}

.products-column .eonsmoke .title-wrap {
  align-items: flex-end;
  color: #6ab131;
  font-size: 14px;
}

@media (min-width: 768px) {
  .products-column .eonsmoke .title-wrap {
    margin-top: 18px;
  }
}

.products-column .eonsmoke .product-title {
  line-height: 1;
}

.products-column .eonsmoke .view-all-btn {
  position: absolute;
  bottom: 15px;
  right: 21px;
  top: unset;
}

.products-column .logic figure {
  margin-top: 16px;
}

.products-column .logic a {
  display: flex;
  height: 100%;
  padding: 18px 21px 18px 16px;
}

.products-column .logic a:hover .title-wrap span {
  text-decoration: underline;
}

.products-column .logic .img-wrap {
  text-align: center;
}

.products-column .logic > div:first-child .title-wrap {
  margin-top: 29px;
  margin-left: 17px;
  background-size: auto;
}

@media (max-width: 1270px) {
  .products-column .logic > div:first-child .title-wrap {
    background: unset;
  }
}

@media (min-width: 1024px) and (max-width: 1270px) {
  .products-column .logic > div:first-child {
    width: 30%;
    flex: unset;
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .products-column .logic > div:first-child .view-all-btn {
    align-self: unset;
    margin-right: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1270px) {
  .products-column .logic > div:first-child .view-all-btn {
    align-self: unset;
  }
}

.products-column .logic > div:nth-child(2) .view-all-btn {
  align-self: unset;
  margin-right: 0;
}

@media (min-width: 1024px) and (max-width: 1270px) {
  .products-column .logic > div:nth-child(2) {
    width: 70%;
    flex: unset;
  }
}

@media (min-width: 1024px) and (max-width: 1270px) {
  .products-column .logic > div:nth-child(2) figure {
    margin-top: 0;
  }
}

.products-column .logic > div:nth-child(2) figure:first-child a {
  background: center/cover no-repeat url("/img/logic_pro_2_backgr.png");
}

.products-column .logic > div:nth-child(2) figure:nth-child(2) a {
  background: center/cover no-repeat url("/img/juul_device_2_backgr.png");
}

.products-column .logic > div:nth-child(2) figure:nth-child(2) .title-wrap {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .products-column .logic > div:nth-child(2) figure:nth-child(2) .title-wrap {
    margin-left: 25px;
  }
}

.products-column .logic .title-wrap {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  hyphens: auto;
  flex: 1;
  margin-left: 26px;
  margin-top: 0;
}

@media (max-width: 767px) {
  .products-column .logic .title-wrap {
    justify-content: center;
  }
}

.products-column .logic .view-all-btn {
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .products-column .logic {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .products-column .logic > div:nth-child(2) figure {
    flex: 0 1 50%;
    max-width: 50%;
  }
  .products-column .logic > div:nth-child(2) figure:first-child {
    margin-right: 16px;
  }
  .products-column .logic .img-wrap {
    display: flex;
    align-self: flex-end;
  }
  .products-column .logic > div {
    flex: 0 0 50%;
  }
  .products-column .logic > div:first-child figure {
    display: block;
    max-width: unset;
    padding-right: 0;
    width: 100%;
    height: 100%;
  }
  .products-column .logic > div:nth-child(2) {
    display: flex;
  }
}

@media (min-width: 1024px) and (max-width: 1270px) {
  .products-column .logic {
    flex-direction: row;
  }
}

@media (min-width: 1270px) {
  .products-column .logic {
    flex: 0 1 60%;
    min-height: 450px;
  }
}

.products-column .logic > div:first-child figure {
  margin-top: 0;
}

@media (max-width: 1270px) {
  .products-column .logic .main-product-logic .title-wrap {
    margin-top: 0 !important;
  }
}

.products-column .logic .main-product-logic figure a {
  background: center/cover no-repeat url("/img/main_product_backgr.png");
}

@media (max-width: 1270px) {
  .products-column .logic .main-product-logic figure a {
    background-position: right;
  }
}

@media (min-width: 1270px) {
  .products-column .logic .main-product-logic figure a .img-wrap {
    margin-left: 20px;
  }
}

@media (min-width: 1270px) {
  .products-column {
    flex: 0 1 40%;
    max-width: 552px;
  }
}

.banner-alert {
  background-color: #fbf03a;
  font-weight: 600;
  margin-top: 21px;
  padding: 15px 30px;
}

/*
    1. 18px is the same as selected product negative top
*/
.featured-products {
  margin-top: 40px;
  padding: 18px 21px 0;
  position: relative;
  display: none;
}

.featured-products::before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  top: 25%;
  right: 0;
  border: 1px solid #6ab131;
  z-index: 0;
  left: 0;
}

.featured-products .reviews-wrap {
  display: flex;
  justify-content: center;
}

.featured-products .product__price {
  justify-content: center;
}

.featured-products .slide-arrows-wrap {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .featured-products {
    padding-top: 55px;
    padding-left: 0;
  }
  .featured-products::before {
    left: 20%;
    top: 0;
  }
  .featured-products .slide-arrows-wrap {
    display: flex;
    position: absolute;
    margin-top: -68px;
    width: calc(50% + 10.5px);
    right: 0;
  }
}

@media (max-width: 681px) {
  .slide-arrows-wrap {
    text-align: center;
  }
}

.featured-products-content {
  position: relative;
  z-index: 1;
}

.featured-products-content .product-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-top: 48px;
  hyphens: auto;
  transition: opacity 0.9s ease-in-out;
}

.featured-products-content .product-info::after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right 0 bottom;
  right: -71px;
  z-index: -1;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .featured-products-content .product-info::after {
    bottom: -90px;
  }
}

@media (min-width: 768px) and (max-width: 1023px), (min-width: 1366px) {
  .featured-products-content .product-info::after {
    display: block;
  }
}

.featured-products-content .product-title {
  position: relative;
  margin-bottom: 0;
}

.featured-products-content .product-title a {
  color: #323332;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.3;
  hyphens: auto;
  background-image: url("/img/pineapple_leaf.png");
  background-repeat: no-repeat;
  padding-bottom: 13px;
  background-position: bottom left;
  margin-bottom: 13px;
}

.featured-products-content .product-title a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .featured-products-content .product-title a {
    font-size: 30px;
    max-width: 350px;
  }
}

.featured-products-content .product-title a .product-title-text {
  margin-bottom: 10px;
}

.featured-products-content .product-flavour {
  font-size: 14px;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

@media (max-width: 1270px) {
  .featured-products-content .product-flavour {
    order: 4;
  }
}

.featured-products-content .product-flavour a {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #323332;
  text-decoration: underline;
}

.featured-products-content .product-flavour a span:first-child {
  margin-bottom: -4px;
}

@media (min-width: 768px) {
  .featured-products-content .product-flavour a {
    font-size: 16px;
    max-width: 255px;
  }
}

@media (max-width: 1270px) {
  .featured-products-content .flavour-options {
    order: 2;
    margin-top: 21px;
  }
}

.featured-products-content .flavour-options a {
  color: #6ab131;
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .featured-products-content .product-info[class*="lush"]:after {
    content: "";
    bottom: -114px;
  }
}

@media (min-width: 1024px) {
  .featured-products-content {
    display: flex;
  }
  .featured-products-content .product-info {
    margin-top: 0;
    padding-bottom: 88px;
  }
}

@media (min-width: 768px) and (max-width: 1024px), (min-width: 1366px) {
  .featured-products-content .product-info[class*="pineapple"]:after {
    content: "";
    background-image: url("/img/pineapple_backgr.png");
  }
  .featured-products-content .product-info[class*="pineapple"] .product-title a:hover {
    color: #f1e085;
  }
  .featured-products-content .product-info[class*="blueberry"]:after {
    content: "";
    background-image: url("/img/blueberries.png");
  }
  .featured-products-content .product-info[class*="blueberry"] .product-title a:hover {
    color: #3a3f60;
  }
  .featured-products-content .product-info[class*="pinklemonade"]:after {
    content: "";
    background-image: url("/img/pinklemonade.png");
  }
  .featured-products-content .product-info[class*="pinklemonade"] .product-title a:hover {
    color: #f87199;
  }
  .featured-products-content .product-info[class*="watmelon"]:after {
    content: "";
    background-image: url("/img/watermelon_flavour.png");
  }
  .featured-products-content .product-info[class*="watmelon"] .product-title a:hover {
    color: #fa3a1e;
  }
  .featured-products-content .product-info[class*="mango"]:after {
    content: "";
    background-image: url("/img/mango_flavour.png");
  }
  .featured-products-content .product-info[class*="mango"] .product-title a:hover {
    color: #ffae01;
  }
  .featured-products-content .product-info[class*="kiwistrawberry"]:after {
    content: "";
    background-image: url("/img/strawberry_kiwi_flavour.png") !important;
  }
  .featured-products-content .product-info[class*="kiwistrawberry"] .product-title a:hover {
    color: #575f2e !important;
  }
  .featured-products-content .product-info[class*="grapple"]:after {
    content: "";
    background-image: url("/img/green_apple_flavour.png");
  }
  .featured-products-content .product-info[class*="grapple"] .product-title a:hover {
    color: #a7cc03;
  }
  .featured-products-content .product-info[class*="strawberry"]:after {
    content: "";
    background-image: url("/img/strawberry_flavour.png");
  }
  .featured-products-content .product-info[class*="strawberry"] .product-title a:hover {
    color: #bc1627;
  }
  .featured-products-content .product-info[class*="strawbanana"]:after {
    content: "";
    background-image: url("/img/strawberries_banana_flavour.png");
  }
  .featured-products-content .product-info[class*="strawbanana"] .product-title a:hover {
    color: #ceae81;
  }
  .featured-products-content .product-info[class*="citrus"]:after {
    content: "";
    background-image: url("/img/citrus_flavour.png");
  }
  .featured-products-content .product-info[class*="citrus"] .product-title a:hover {
    color: #e3c56c;
  }
  .featured-products-content .product-info[class*="cucumber"]:after {
    content: "";
    background-image: url("/img/cucmber_flavour.png");
  }
  .featured-products-content .product-info[class*="cucumber"] .product-title a:hover {
    color: #b2d189;
  }
  .featured-products-content .product-info[class*="peach"]:after {
    content: "";
    background-image: url("/img/peach_flavour.png");
  }
  .featured-products-content .product-info[class*="peach"] .product-title a:hover {
    color: #e05c54;
  }
  .featured-products-content .product-info[class*="blras"]:after {
    content: "";
    background-image: url("/img/blue_raspberry_flavour.png");
  }
  .featured-products-content .product-info[class*="blras"] .product-title a:hover {
    color: #5362d0;
  }
  .featured-products-content .product-info[class*="pomeg"]:after {
    content: "";
    background-image: url("/img/pomegrenade_flavour.png");
  }
  .featured-products-content .product-info[class*="pomeg"] .product-title a:hover {
    color: #db5d6a;
  }
  .featured-products-content .product-info[class*="lush"]:after {
    content: "";
    background-image: url("/img/lush_flavour.png");
    right: 7%;
  }
  .featured-products-content .product-info[class*="lush"] .product-title a:hover {
    color: #a6e603;
  }
  .featured-products-content .product-info[class*="cubano"]:after {
    content: "";
    background-image: url("/img/cubano_flavour.png");
  }
  .featured-products-content .product-info[class*="cubano"] .product-title a:hover {
    color: #d1905c;
  }
}

.products-slide-wrap {
  flex: 0 1 40%;
  height: 400px;
  margin: 0 auto 30px;
  max-width: 536px;
}

@media (min-width: 1024px) {
  .products-slide-wrap {
    margin-right: 10%;
    margin-bottom: 0;
  }
}

#products-slide {
  display: none;
}

#products-slide-version-2 {
  position: relative;
  display: flex;
}

#products-slide-version-2 img {
  display: block;
  margin: 0 auto 51px;
  max-height: 70%;
  height: 70%;
}

#products-slide-version-2 > div {
  width: 51.5%;
  height: 400px;
  transform: translateX(-50%);
  position: absolute;
  transition: all 0.9s ease-in-out;
}

#products-slide-version-2 > div a {
  display: block;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 17px;
  height: 100%;
  color: #323332;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

@media (min-width: 767px) {
  #products-slide-version-2 > div a {
    padding: 21px 21px 40px;
  }
}

#products-slide-version-2 .prev {
  left: 26%;
  z-index: 1000;
}

#products-slide-version-2 .next {
  left: 69%;
  z-index: 1100;
  left: auto;
  right: 0;
  transform: none;
}

#products-slide-version-2 .selected {
  left: 50%;
  top: -18px;
  transform: translateX(-50%);
  z-index: 1200;
  height: 435px;
  min-width: 260px;
}

@media (min-width: 767px) {
  #products-slide-version-2 .prev {
    margin-right: 28px;
  }
}

#products-slide-version-2 .next {
  display: block;
}

@media (min-width: 767px) {
  #products-slide-version-2 .next {
    margin-left: 28px;
  }
}

.login-link {
  display: none;
}

.login-link a {
  text-decoration: underline;
  color: #323439;
}

@media (min-width: 1024px) {
  .login-link {
    font-size: 12px;
    position: absolute;
    top: 21px;
    right: 21px;
    z-index: 2;
  }
}

.view-all-flavours-btn {
  text-align: right;
  position: relative;
  z-index: 1;
  margin-top: 15px;
  padding-bottom: 30px;
}

@media (max-width: 1024px) {
  .view-all-flavours-btn {
    text-align: left;
  }
}

.slide-left-arrow,
.slide-right-arrow {
  background-repeat: no-repeat;
  border: 2px solid #cdcfd1;
  background-position: center;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
}

.slide-left-arrow:hover,
.slide-right-arrow:hover {
  border-color: #6ab131;
}

@media (min-width: 1024px) {
  .slide-left-arrow,
  .slide-right-arrow {
    width: 60px;
    height: 60px;
  }
}

.slide-left-arrow {
  background-image: url("/img/icon-slide-arrow-left.png");
}

@media (min-width: 1024px) {
  .slide-left-arrow {
    margin-right: 10px;
  }
}

.slide-right-arrow {
  background-image: url("/img/icon-slide-arrow-right.png");
}

@media (min-width: 1024px) {
  .slide-right-arrow {
    margin-left: 10px;
  }
}

.home-section {
  margin-top: 45px;
}

.home-section > h2 {
  padding-bottom: 22px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  background: url("/img/pineapple_leaf.png") bottom no-repeat;
  background-repeat: no-repeat;
  margin: 0 0 40px;
}

@media (min-width: 1024px) {
  .home-section {
    margin-top: 90px;
  }
}

.featured-categories-wrap {
  display: none;
}

.featured-categories-wrap .product-title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 300;
}

@media (min-width: 1280px) {
  .featured-categories-wrap {
    display: flex;
    flex-direction: column;
  }
  .featured-categories-wrap .cat-row-1 {
    display: flex;
    width: 100%;
    box-sizing: content-box;
    margin-bottom: 38px;
  }
  .featured-categories-wrap .cat-row-1 > figure:first-child {
    padding-right: 14.5px;
  }
  .featured-categories-wrap .cat-row-1 > figure:nth-child(2) {
    padding-left: 14.5px;
    padding-right: 14.5px;
  }
  .featured-categories-wrap .cat-row-1 > figure:nth-child(3) {
    padding-left: 14.5px;
  }
  .featured-categories-wrap .cat-row-2 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .featured-categories-wrap .cat-row-2 > figure:first-child {
    padding-right: 14.5px;
  }
  .featured-categories-wrap .cat-row-2 > figure:last-child {
    padding-left: 14.5px;
  }
  .featured-categories-wrap figure {
    width: 33.3%;
    max-width: 422px;
    box-sizing: content-box;
    min-height: 470px;
  }
  .featured-categories-wrap a {
    background-color: #fff;
    display: block;
    height: 100%;
    text-align: center;
    background-color: #fff;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 100%;
  }
  .featured-categories-wrap a:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
  }
  .featured-categories-wrap img {
    display: block;
    margin: 0 auto;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
  }
}

.blog-posts-cont .blog-post-title {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  min-height: 60px;
  margin: 0;
}

.blog-posts-cont .blog-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1;
}

.blog-posts-cont a {
  display: block;
  position: relative;
  padding: 0;
  overflow: hidden;
  height: 345px;
}

.blog-posts-cont a img {
  display: block;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  height: 100%;
}

.blog-posts-cont a:hover {
  text-decoration: none;
}

.blog-posts-cont a:hover img {
  transform: scale(1.1);
}

.blog-posts-cont a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.review-item {
  height: 100%;
}

.review-item > div {
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding: 21px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .review-item > div {
    flex-direction: row;
    padding: 30px;
  }
}

.author-wrap {
  padding-top: 12px;
  text-align: center;
}

@media (min-width: 1px) and (max-width: 1023px) {
  .author-wrap {
    border-bottom: 1px solid #f4f6f9;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .author-wrap {
    border-right: 1px solid #f4f6f9;
    padding-right: 30px;
    margin-right: 30px;
  }
}

.author-wrap #author-info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
  color: #a6a8a9;
  text-align: center;
}

.author-wrap #author-info span {
  max-width: 100%;
  margin: 0 auto;
  width: 70%;
}

.author-wrap .date-published {
  font-size: 12px;
  text-align: center;
}

.author-wrap [id*="review"] {
  width: 103px;
  height: 104px;
  background-color: #f4f6f9;
  display: inline-block;
  border-radius: 50%;
}

.author-wrap [id*="review"] > span {
  width: 103px;
  height: 104px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  position: relative;
  top: -12px;
}

.review-content-wrap .review-off {
  font-size: 12px;
  line-height: 1.5;
}

.review-content-wrap .product-title {
  color: inherit;
  text-decoration: underline;
}

.review-content-wrap .product-title:hover {
  color: #528926;
}

.review-content-wrap .review-title {
  font-weight: 600;
  margin: 0;
  padding-bottom: 0;
}

@media (min-width: 767px) {
  .review-content-wrap .review-title {
    font-size: 1.250rem;
  }
}

.review-content-wrap .review-text {
  font-size: 0.875rem;
  padding-top: 8px;
}

.review-content-wrap .stars {
  margin: 15px 0 9px;
}

.reviews-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.about-area-wrap {
  background-color: #f4f6f9;
  position: relative;
  border: 1px solid #cdcfd1;
  margin-top: 42px;
}

@media (min-width: 1310px) {
  .about-area-wrap {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1310px) {
  .about-area-wrap .welcome-wrap {
    transform: rotate(90deg);
    align-self: flex-start;
    position: relative;
    top: 90px;
    border-top: 1px solid #d4d7d9;
    padding-right: 21px;
  }
}

.about-area-wrap .welcome-wrap .welcome-title {
  text-align: center;
  margin-bottom: 0;
}

@media (min-width: 1310px) {
  .about-area-wrap .welcome-wrap .welcome-title {
    background: unset;
    position: relative;
  }
  .about-area-wrap .welcome-wrap .welcome-title::after {
    content: "";
    position: absolute;
    width: 66px;
    height: 8px;
    top: 10px;
    background: url(/img/pineapple_leaf.png) 0 0 no-repeat;
    transform: rotate(90deg);
    right: -42px;
  }
}

.about-area-wrap .about-area-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 767px) {
  .about-area-wrap .about-area-text {
    display: flex;
    font-size: 14px;
  }
  .about-area-wrap .about-area-text > .col:first-child {
    padding-right: 21px;
    flex: 0 1 50%;
  }
  .about-area-wrap .about-area-text > .col + .col {
    padding-left: 21px;
    flex: 0 1 50%;
  }
}

.about-area-wrap .about-area-content {
  padding: 21px;
}

@media (min-width: 1310px) {
  .about-area-wrap .about-area-content {
    padding-top: 0;
    padding-right: 35px;
    padding-top: 38px;
  }
}

.about-area-wrap .about-area-content .read-more-wrap {
  text-align: right;
  margin: 30px 0 0;
}

.footer {
  margin-top: 0;
}
