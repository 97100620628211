
.featured-categories-wrap {
    display: none;
    // margin-left: -$carousel-item-padding-x;
    // margin-right: -$carousel-item-padding-x;

    .product-title {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        line-height: 50px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index:300;
    }
    

    @media (min-width: 1280px) {
        display: flex;
        flex-direction: column;


        .cat-row-1 {
            display: flex;
            width: 100%;
            box-sizing: content-box;
            margin-bottom: 38px;

             > figure {
                &:first-child {
                    padding-right: 14.5px;
                }

                &:nth-child(2) {
                    padding-left: 14.5px;
                    padding-right: 14.5px;
                }

                &:nth-child(3) {
                    padding-left: 14.5px;
                }
             }
        }

        .cat-row-2 {
            display: flex;
            width: 100%;
            justify-content: center;


            > figure {
                &:first-child {
                    padding-right: 14.5px;
                }

                &:last-child {
                    padding-left: 14.5px;
                }
             }
        }

         figure {
            // padding-left: $carousel-item-padding-x;
            // padding-right: $carousel-item-padding-x;
            width: 33.3%;
            max-width: 422px;
            box-sizing: content-box;
            min-height: 470px;
        }

        a {
            background-color: $white;
            display: block;
            height: 100%;
            text-align: center;
            background-color: #fff;
            text-decoration: none;
            position: relative;
            display: flex;
            align-items: center;
            padding-top: 100%;

            &:hover {
                box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
            }
        }

        img {
            display: block;
            margin: 0 auto;
            // height: 300px;
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top:0;
            left:0;
            z-index:200;
        }
    }
}