// Making changes to this file
// 
// 1. Enable the overwrite variable related to this component in _variables.scss under "component overwrites"
// 2. Changes here will only affect the component in all pages its been added

@mixin carousel {
    //
// Styles for Owl Carousel 
//

.owl-carousel {
    position: relative;
  
    // ---
    // nav/dots common
    .owl-nav,
    .owl-dots {
      padding: 0;
      width: auto;
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        outline: none;
  
        &.disabled {
          opacity: 0.75;
          cursor: default;
          pointer-events: none;
        }
  
        &.active {
          cursor: default;
        }
  
        span {
          width: 100%;  
          display: none;
        }
  
      }
  
      &.disabled {
        display: none;
      }
    }
  
    // ---
    // nav
    $carousel-nav-icon-width: 49px;
  
    .owl-nav {
      width: auto;
  
      svg {
        display: block;
        margin: 0;
        position: absolute;
      }
  
      button {
        display: flex;
          align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $carousel-nav-icon-width;
        height: 49px;
  
        &:hover {
          color: #719d00;
        }
      }
  
      .owl-prev {
        background-image: url("/img/icon-slide-arrow-left.png");
      }
  
      .owl-next {
        background-image: url("/img/icon-slide-arrow-right.png");
      }

      .owl-prev, .owl-next {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            background-repeat: no-repeat;
            border: 2px solid #cdcfd1;
            background-position: center;
            display: inline-block;
            cursor: pointer;
            width: 61px;
            height: 61px;
            z-index: 1500;

            &:hover {
                border-color: #6ab131;
            }
      }
    }
  
    .owl-prev {
      left: calc(-#{$carousel-nav-icon-width} - 30px);
  
      svg {
        margin-left: -9px;
      }
    }
  
    .owl-next {
      right: calc(-#{$carousel-nav-icon-width} - 30px);
    
  
      svg {
        margin-left: -9px;
      }
    }
  
  
    // ---
    // dots
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      width: 100%;
  
      button {
        border: 2px solid #719d00;;
        background: #fff;
        border-radius: 50%;
        height: 12px; 
        width: 12px;
  
        &:hover,
        &.active {
          background: #fff;
        }
  
        &.active {
          border-color: #6ab131;
          background-color: #6ab131;
          height: 19px;
          width: 19px;
          max-width: 19px;
          flex: 0 1 19px;
        }
      }
  
      button + button {
        margin-left: 9px;
      }
    }
  
    .owl-dots:empty {
      display: none;
    }
  }
}

