// Featured products area

$featured-products-padding-x: 21px;
$product-selected-top: 18px;

/*
    1. 18px is the same as selected product negative top
*/
.featured-products {
    margin-top: 40px;
    padding: $product-selected-top $featured-products-padding-x 0; // 1
    position: relative;
    display: none;

    // green border
    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 0;
        top: 25%;
        right: 0;
        border: 1px solid #6ab131;
        z-index: 0;
        left: 0;
    }

    // reviews
    .reviews-wrap {
        display: flex;
        justify-content: center;
    }

    // price
    .product__price {
        justify-content: center;
    }

    // arrows
    .slide-arrows-wrap {
        margin-top: 15px;
    }

    // ------
    // responsive
    @media (min-width: 1024px) {
        padding-top: 55px;
        padding-left: 0;

        // green border
        &::before {
            left: 20%;
            top: 0;
        }

        // arrows
        .slide-arrows-wrap {
            display: flex;
            position: absolute;
            margin-top: -68px;
            width: calc(50% + #{$featured-products-padding-x / 2});
            right: 0;
        }
    }
}

@media (max-width: 681px) {
    .slide-arrows-wrap {
        text-align: center;
    }
}

.featured-products-content {
    position: relative;
    z-index: 1;

    .product-info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        margin-top: 48px;
        hyphens: auto;
        transition: opacity 0.9s ease-in-out;

        &::after {
            display: none;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            background-repeat: no-repeat;
            background-position: right 0 bottom;
            right: -71px;
            z-index: -1;

            @media (min-width: 768px) and (max-width: 1023px) {
                bottom: -90px;
            }

            @media (min-width: 768px) and (max-width: 1023px),
                (min-width: 1366px) {
                display: block;
            }
        }
    }

    .product-title {
        position: relative;
        margin-bottom: 0;

        a {
            &:hover {
                text-decoration: none;
            }

            color: #323332;
            font-size: 21px;
            font-weight: 600;
            line-height: 1.3;
            hyphens: auto;
            background-image: url("/img/pineapple_leaf.png");
            background-repeat: no-repeat;
            padding-bottom: 13px;
            background-position: bottom left;
            margin-bottom: 13px;

            @media (min-width: 768px) {
                font-size: 30px;
                max-width: 350px;
            }

            .product-title-text {
                margin-bottom: 10px;
            }
        }
    }

    .product-flavour {
        font-size: 14px;
        text-decoration: none;
        position: relative;
        z-index: 1;

        @media (max-width: 1270px) {
            order: 4;
        }

        a {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            color: #323332;
            text-decoration: underline;

            span:first-child {
                margin-bottom: -4px;
            }

            @media (min-width: 768px) {
                font-size: 16px;
                max-width: 255px;
            }
        }
    }

    .flavour-options {
        @media (max-width: 1270px) {
            order: 2;
            margin-top: 21px;
        }

        a {
            color: #6ab131;
            font-size: 14px;
            font-weight: 400;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        .product-info[class*="lush"] {
            &:after {
                content: "";
                bottom: -114px;
            }
        }
    }

    // ---
    // responsive
    @media (min-width: 1024px) {
        display: flex;

        .product-info {
            margin-top: 0;
            padding-bottom: 88px;
        }
    }

    // only load images on desktop
    @media (min-width: 768px) and (max-width: 1024px), (min-width: 1366px) {
        .product-info[class*="pineapple"] {
            &:after {
                content: "";
                background-image: url("/img/pineapple_backgr.png");
            }

            .product-title {
                a {
                    &:hover {
                        color: #f1e085;
                    }
                }
            }
        }

        .product-info[class*="blueberry"] {
            &:after {
                content: "";
                background-image: url("/img/blueberries.png");
            }

            .product-title {
                a {
                    &:hover {
                        color: #3a3f60;
                    }
                }
            }
        }

        .product-info[class*="pinklemonade"] {
            &:after {
                content: "";
                background-image: url("/img/pinklemonade.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #f87199;
                    }
                }
            }
        }

        .product-info[class*="watmelon"] {
            &:after {
                content: "";
                background-image: url("/img/watermelon_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #fa3a1e;
                    }
                }
            }
        }

        .product-info[class*="mango"] {
            &:after {
                content: "";
                background-image: url("/img/mango_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #ffae01;
                    }
                }
            }
        }

        .product-info[class*="kiwistrawberry"] {
            &:after {
                content: "";
                background-image: url("/img/strawberry_kiwi_flavour.png") !important;
            }
            .product-title {
                a {
                    &:hover {
                        color: #575f2e !important;
                    }
                }
            }
        }

        .product-info[class*="grapple"] {
            &:after {
                content: "";
                background-image: url("/img/green_apple_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #a7cc03;
                    }
                }
            }
        }

        .product-info[class*="strawberry"] {
            &:after {
                content: "";
                background-image: url("/img/strawberry_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #bc1627;
                    }
                }
            }
        }

        .product-info[class*="strawbanana"] {
            &:after {
                content: "";
                background-image: url("/img/strawberries_banana_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #ceae81;
                    }
                }
            }
        }

        .product-info[class*="citrus"] {
            &:after {
                content: "";
                background-image: url("/img/citrus_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #e3c56c;
                    }
                }
            }
        }

        .product-info[class*="cucumber"] {
            &:after {
                content: "";
                background-image: url("/img/cucmber_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #b2d189;
                    }
                }
            }
        }

        .product-info[class*="peach"] {
            &:after {
                content: "";
                background-image: url("/img/peach_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #e05c54;
                    }
                }
            }
        }

        .product-info[class*="blras"] {
            &:after {
                content: "";
                background-image: url("/img/blue_raspberry_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #5362d0;
                    }
                }
            }
        }

        .product-info[class*="pomeg"] {
            &:after {
                content: "";
                background-image: url("/img/pomegrenade_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #db5d6a;
                    }
                }
            }
        }
        .product-info[class*="lush"] {
            &:after {
                content: "";
                background-image: url("/img/lush_flavour.png");
                right: 7%;
            }
            .product-title {
                a {
                    &:hover {
                        color: #a6e603;
                    }
                }
            }
        }
        .product-info[class*="cubano"] {
            &:after {
                content: "";
                background-image: url("/img/cubano_flavour.png");
            }
            .product-title {
                a {
                    &:hover {
                        color: #d1905c;
                    }
                }
            }
        }
    }
}

.products-slide-wrap {
    flex: 0 1 40%;
    height: 400px;
    margin: 0 auto 30px;
    max-width: 536px;

    @media (min-width: 1024px) {
        margin-right: 10%;
        margin-bottom: 0;
    }
}

#products-slide {
    display: none;
}

#products-slide-version-2 {
    position: relative;
    display: flex;

    img {
        display: block;
        margin: 0 auto 51px;
        max-height: 70%;
        height: 70%;
    }

    > div {
        width: 51.5%;
        height: 400px;
        transform: translateX(-50%);
        position: absolute;
        transition: all 0.9s ease-in-out;

        a {
            display: block;
            text-align: center;
            box-sizing: border-box;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            padding: 17px;
            height: 100%;
            color: #323332;
            font-size: 12px;
            font-weight: 400;
            text-decoration: none;

            @media (min-width: 767px) {
                padding: 21px 21px 40px;
            }
        }
    }

    .prev {
        left: 26%;
        z-index: 1000;
    }

    .next {
        left: 69%;
        z-index: 1100;
        left: auto;
        right: 0;
        transform: none;
    }

    .selected {
        left: 50%;
        top: -$product-selected-top;
        transform: translateX(-50%);
        z-index: 1200;
        height: 435px;
        min-width: 260px;
    }

    .prev {
        @media (min-width: 767px) {
            margin-right: 28px;
        }
    }

    .next {
        display: block;
        @media (min-width: 767px) {
            margin-left: 28px;
        }
    }
}

.login-link {
    display: none;

    a {
        text-decoration: underline;
        color: #323439;
    }

    @media (min-width: 1024px) {
        font-size: 12px;
        position: absolute;
        top: 21px;
        right: $featured-products-padding-x;
        z-index: 2;
    }
}

.view-all-flavours-btn {
    text-align: right;
    position: relative;
    z-index: 1;
    margin-top: 15px;
    padding-bottom: 30px;

    @media (max-width: 1024px) {
        text-align: left;
    }
}

// ------
// arrows
.slide-left-arrow,
.slide-right-arrow {
    background-repeat: no-repeat;
    border: 2px solid #cdcfd1;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1;

    &:hover {
        border-color: #6ab131;
    }

    @media (min-width: 1024px) {
        width: 60px;
        height: 60px;
    }
}

.slide-left-arrow {
    background-image: url("/img/icon-slide-arrow-left.png");

    @media (min-width: 1024px) {
        margin-right: 10px;
    }
}

.slide-right-arrow {
    background-image: url("/img/icon-slide-arrow-right.png");

    @media (min-width: 1024px) {
        margin-left: 10px;
    }
}
