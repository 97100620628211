.product__price {
    color: $product-price-color;
    display: flex;
    font-size: rem($product-price-font-size);
    font-weight: $product-price-font-weight;
    line-height: calc(
        #{rem($product-price-font-size) + #{$product-line-height}}
    );
    margin-top: $product-listing-price-spacing-y;
    order: $product-listing-price-order;

    [class^="price"]:not(:last-child) {
        margin-right: 6px;
    }

    [class^="price"]:empty {
        display: none;
        margin-right: 0;
    }
}

.price--normal {
    color: $product-price-normal-color;
    font-weight: $product-price-normal-font-weight;
    text-decoration: $product-price-normal-text-decoration;
}

.price--special {
    color: $product-price-special-color;
    order: -1;
}

.price--off {
    display: none;
    color: $product-price-off-color;
    font-weight: $font-weight-normal;
    margin-left: 6px;
}